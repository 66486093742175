import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Container, Typography, Button, Card } from "@mui/material";
import { AddShoppingCart, RemoveShoppingCart } from "@mui/icons-material";
import InnerLayout from "../../layouts/InnerLayout";
import InnerBanner from "../../images/inner-banner.jpg";
import Grid from "@mui/material/Grid2";
import CallForAction from "../../components/call_for_action/CallForActtion";
import apiService from "../../services/api";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function PujaDetails() {
  const { location } = useParams();
  const navigate = useNavigate();
  const [pujas, setPujas] = useState([]);
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(true);

  // Load cart from localStorage when the component mounts
  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCart(Array.isArray(savedCart) ? savedCart : []); // Ensure cart is always an array

    const fetchPujas = async () => {
      try {
        const response = await apiService.getPujas();
        if (response.data.success) {
          setPujas(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching pujas:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPujas();
  }, []);

  const filteredPujas = pujas.filter((puja) =>
    location === "temple"
      ? puja.temple_amount > 0
      : puja.home_amount > 0
  );

  const addToCart = (puja) => {
    setCart((prevCart) => {
      const updatedCart = [...prevCart, puja];
      localStorage.setItem("cart", JSON.stringify(updatedCart)); // Save to localStorage
      return updatedCart;
    });
  };

  const removeFromCart = (pujaId) => {
    setCart((prevCart) => {
      const updatedCart = prevCart.filter((item) => item.id !== pujaId);
      localStorage.setItem("cart", JSON.stringify(updatedCart)); // Save to localStorage
      return updatedCart;
    });
  };

  const isInCart = (pujaId) =>
    Array.isArray(cart) && cart.some((item) => item.id === pujaId);

  const calculateTotal = () => {
    return cart.reduce(
      (total, item) =>
        total + (location === "temple" ? item.temple_amount : item.home_amount),
      0
    );
  };

  const handleCheckout = () => {
    navigate("/checkout", { state: { cart, location } });
  };

  return (
    <InnerLayout>
      <Box component="section" className="inner-banner" sx={{ p: 0 }}>
        <Box component="img" className="banner-pic" src={InnerBanner} />

        <Box className="inner-text">
          <Container maxWidth="xl">
            <Grid
              container
              spacing={2}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item size={{ md: 6 }}>
                <Typography
                  component="h1"
                  variant="h1"
                  className="section-title text-white"
                >
                  Puja Details (
                  {location.charAt(0).toUpperCase() + location.slice(1)})
                </Typography>
              </Grid>
              <Grid item size={{ md: 6 }}>
                <CallForAction />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>

      <Box component="section" sx={{ py: 4 }}>
        <Container maxWidth="xl">
          {loading ? (
            <Typography>Loading...</Typography>
          ) : filteredPujas.length > 0 ? (
            <Box className="project-cart">
              <Grid
                container
                spacing={2}
                sx={{
                  justifyContent: "start",
                  alignItems: "start",
                }}
              >
                <Grid size={{ xs: 12, md: 8 }} order={{ xs: 2, md: 1 }}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      justifyContent: "start",
                      alignItems: "start",
                    }}
                  >
                    {filteredPujas.map((puja) => (
                      <Grid item key={puja.id} size={{ xs: 12, md: 6 }}>
                        <Card
                          className={`custom-card project-cart-list ${
                            isInCart(puja.id) ? "active" : ""
                          }`}
                          onClick={() =>
                            isInCart(puja.id)
                              ? removeFromCart(puja.id)
                              : addToCart(puja)
                          }
                          sx={{ cursor: "pointer", p: 2 }}
                        >
                          <CheckCircleIcon className="check-mark" />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "start",
                            }}
                          >
                            <Typography
                              variant="h5"
                              className="text-secondary font-400"
                            >
                              {puja.name}
                            </Typography>
                            <Typography
                              variant="h5"
                              className="text-primary font-700"
                            >
                              $
                              {location === "temple"
                                ? puja.temple_amount
                                : puja.home_amount}
                            </Typography>
                          </Box>

                          <Button
                            variant="contained"
                            color={isInCart(puja.id) ? "secondary" : "primary"}
                            onClick={(event) => {
                              event.stopPropagation(); // Prevents the card click event from firing
                              isInCart(puja.id)
                                ? removeFromCart(puja.id)
                                : addToCart(puja);
                            }}
                            sx={{ mt: 2 }}
                          >
                            {isInCart(puja.id) ? (
                              <RemoveShoppingCart />
                            ) : (
                              <AddShoppingCart />
                            )}
                            {isInCart(puja.id)
                              ? " Remove from Cart"
                              : " Add to Cart"}
                          </Button>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid className="sticky" size={{ xs:12, md: 4 }} order={{ xs: 1, md: 2 }} sx={{ position: "sticky", top: "80px", alignSelf: "start" }}>
  <Box>
    <Card className="custom-card no-shadow mobile-card">
      <Typography
        variant="h4"
        className="text-primary font-700 border-title"
      >
        Details
      </Typography>

      <Box className="selected-halls">
        <Box sx={{ my: 2 }} className="selected-block">
          {cart.length > 0 ? (
            cart.map((item) => (
              <Box
                key={item.id}
                sx={{ mb: 1 }}
                className="selected-hall-item"
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  className="items-list"
                >
                  <Typography variant="p" component="span">
                    <CheckCircleIcon /> {item.name}
                  </Typography>
                  <Typography
                    variant="p"
                    component="span"
                    className="font-700 text-primary"
                  >
                    ${location === "temple" ? item.temple_amount : item.home_amount}
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            <Typography variant="h6" sx={{ mt: 2 }}>
              No items in cart
            </Typography>
          )}
        </Box>

        {cart.length > 0 && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className="grand-total"
          >
            <Typography
              variant="h5"
              component="span"
              className="font-700"
            >
              Total:
            </Typography>
            <Typography
              variant="h5"
              component="span"
              className="font-700 text-primary"
            >
              $ {calculateTotal()}
            </Typography>
          </Box>
        )}
      </Box>

      <Box className="text-center bg-grey checkout-footer">
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          onClick={handleCheckout}
          disabled={cart.length === 0}
        >
          Checkout
        </Button>
      </Box>
    </Card>
  </Box>
</Grid>
              </Grid>
            </Box>
          ) : (
            <Typography>No pujas available for {location}.</Typography>
          )}
        </Container>
      </Box>
    </InnerLayout>
  );
}

export default PujaDetails;
