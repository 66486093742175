import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Card, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InnerLayout from "../layouts/InnerLayout";
import InnerBanner from "../images/inner-banner.jpg";

const SuccessPage = () => {
  const navigate = useNavigate();
  const [pageState, setPageState] = useState(null);

  useEffect(() => {
    // Check if state exists in sessionStorage
    const storedState = sessionStorage.getItem("successPageState");

    if (storedState) {
      setPageState(JSON.parse(storedState));
      sessionStorage.removeItem("successPageState"); // Optionally, clear the state after usage
    } else {
      navigate("/"); // Redirect if state is not found (page accessed directly)
    }
  }, [navigate]);

  if (!pageState) {
    // If the state is still not set, return null or a loading message
    return <Typography variant="h6" align="center">Loading...</Typography>;
  }

  const { type, requestId, extraMessage } = pageState;
  
  // Default message when request type is not recognized
  let message = "Thank you! Your request has been successfully placed. Please check your email for further details.";
  
  // Customize message based on request type
  if (type === "puja-request") {
    message = "Your puja request has been successfully placed. Please check your email for further details.";
  } else if (type === "temple-tour") {
    message = "Your tour request has been successfully placed. Please check your email for further details.";
  } else if (type === "social-hall-booking") {
    message = "Your hall booking request has been successfully placed. Please check your email for further details.";
  } else if( type === "change-social-hall"){
    message = "Your request For Changing the Hall  has been successfully placed. Please check your email for further details.";
  }
  else if( type === "cancel-social-hall"){
    message = "Your request For Cancelling the Hall  has been successfully placed.";
  }
  else if( type === "change-puja-request"){
    message = "Your request For Changing the Puja Request has been successfully placed.";

  }
  else if( type === "cancel-puja-request"){
    message = "Your request For Cancelloing the Puja Request has been successfully placed.";

  }
  // Determine the correct request type message based on the request type
  const requestTypeMessage = type === "puja-request"
  ? "Puja Request ID:"
  : type === "temple-tour"
  ? "Tour Request ID:"
  : type === "social-hall-booking"
  ? "Hall Booking Request ID:"
  : type === "change-social-hall"
  ? "Change Hall Booking Request ID:"
  : type === "cancel-social-hall"
  ? "Cancel Hall Booking Request ID:"
  : type === "change-puja-request"
  ? "Change Puja Request ID:"
  : type === "cancel-puja-request"
  ? "Cancel Puja Request ID:"
  : "Request ID:";


  // Add extra information if provided in the state
  const additionalInfo = extraMessage ? (
    <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
      {extraMessage}
    </Typography>
  ) : null;

  return (
    <InnerLayout>
      <Box component="section" className="inner-banner success-banner" sx={{ p: 0 }}>
        <Box component="img" className="banner-pic" src={InnerBanner} alt="Inner Banner" />
      </Box>

      <Box className="inner-container text-center min-height" component="section">
        <Container maxWidth="xl">
          <Card className="custom-card success-card">
            <Typography component="h1" variant="h1" className="text-primary">
              THANK YOU!
            </Typography>
            <Typography variant="h5" color="success.main">
              {message}
            </Typography>
            {requestId && (
              <Typography variant="h5" className="mt-30">
                {requestTypeMessage} <strong>{requestId}</strong>
              </Typography>
            )}
            {additionalInfo}
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 3 }}
              onClick={() => navigate("/")}
            >
              Go To Homepage
            </Button>
          </Card>
        </Container>
      </Box>
    </InnerLayout>
  );
};

export default SuccessPage;
