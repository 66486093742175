import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import SocialHallBooking from "../pages/social_hall_booking/SocialHallBooking.js";
import TempleToursRequest from "../pages/temple_tours_request/TempleToursRequest.js";
import Events from "../pages/event/Event.js";
import EventDetails from "../pages/event/EventDetails";
import DynamicLayout from "../layouts/DynamicLayout.js";
import PujaRequest from "../pages/puja_request/PujaRequest.js";
import PujaDetails from "../pages/puja_request/PujaDetails.js";
import Checkout from "../pages/puja_request/Checkout.js";
import SuccessPage from "../pages/SuccessPage.js";
import ChangeHallBooking from "../pages/social_hall_booking/ChangeHallBooking.js";
import CancelHallBooking from "../pages/social_hall_booking/CancelHallBooking.js";
import ChangePujaRequest from "../pages/puja_request/ChangePujaRequest.js";
import CancelPujaRequest from "../pages/puja_request/CancelPujaRequest.js";


function Router({ theme, toggleTheme }) {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/events" element={<Events />} />
      <Route path="/events/:slug" element={<EventDetails />} />
      <Route path="/puja-request" element={<PujaRequest />} />
      <Route path="/change-puja-request" element={<ChangePujaRequest />} />
      <Route path="/cancel-puja-request" element={<CancelPujaRequest />} />
      <Route path="/puja-details/:location" element={<PujaDetails />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/book-now" element={<SocialHallBooking />} />
      <Route path="/change-hall-booking" element={<ChangeHallBooking />} />
      <Route path="/temple-tour" element={<TempleToursRequest />} />
      <Route path="/success" element={<SuccessPage />} />
      <Route path="/:slug" element={<DynamicLayout />} />
      <Route path="/cancel-hall-booking" element={<CancelHallBooking />} />

    </Routes>
  );
}

export default Router;
