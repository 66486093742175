import React from 'react';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Box } from '@mui/material';

function LoadingIcon() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
      }}
    >
      <NotificationsActiveIcon
        sx={{
          fontSize: 50,
          animation: 'bell 1s infinite',
        }}
      />
      <style>
        {`
          @keyframes bell {
            0% { transform: rotate(0deg); }
            25% { transform: rotate(15deg); }
            50% { transform: rotate(0deg); }
            75% { transform: rotate(-15deg); }
            100% { transform: rotate(0deg); }
          }
        `}
      </style>
    </Box>
  );
}

export default LoadingIcon;
