import React, { useState, useEffect } from "react";
import { Box, Container, Typography, TextField, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import { useAppSettings } from "../../contexts/AppSettingsContext";

function JoinOurCommunication() {
  const { whatsappScanImage } = useAppSettings() || {};
  const [email, setEmail] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const form = document.getElementById("mc-embedded-subscribe-form");
    const handleSubmit = async (e) => {
      e.preventDefault();
      setResponseMessage("");
      setIsError(false);

      const formData = new FormData(form);
      try {
        await fetch(form.action, {
          method: "POST",
          body: formData,
          mode: "no-cors",
        });
        setResponseMessage("Successfully subscribed! Check your email to confirm.");
        setEmail(""); // Clear input on success
      } catch (error) {
        setIsError(true);
        setResponseMessage("Failed to subscribe. Please try again later.");
        console.error("Subscription error:", error);
      }
    };

    if (form) {
      form.addEventListener("submit", handleSubmit);
      return () => form.removeEventListener("submit", handleSubmit);
    }
  }, []);

  return (
    <Box component="section" className="bg-tertiary text-white call-to-action">
      <Container maxWidth="xl">
        <Grid
          container
          className="call-top"
          spacing={2}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item size={{ md: 4 }} sx={{ px: 3 }}>
            <Typography
              component="h3"
              variant="h2"
              color="text.primary"
              className="text-white"
            >
              Join our Communication to get Notifications
            </Typography>
            <Box className="face-book-block" sx={{ mt: 3 }}>
              <Button
                variant="contained"
                color="white"
                href="https://www.facebook.com/HinduTemple.Omaha.Nebraska"
                target="_blank"
                className="text-primary"
              >
                <FacebookOutlinedIcon /> Follow us on Facebook
              </Button>
            </Box>
          </Grid>

          {/* Mailchimp Embedded Signup Form with MUI TextField */}
          <Grid item size={{ md: 4 }} sx={{ px: 3 }} className="form">
            <div id="mc_embed_signup">
              <form
                action="https://f9tech.us11.list-manage.com/subscribe/post?u=00ac2b75d1740071c7b3b16f1&id=e85ec95097&f_id=00a01fe1f0"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
              >
                <div id="mc_embed_signup_scroll">
                  <Typography variant="h6" component="h2" sx={{ mb: 1 }}>
                    Subscribe
                  </Typography>
                  <Typography sx={{ mb: 2 }}>
                    <span className="asterisk" style={{ color: "red" }}>
                      *
                    </span>{" "}
                    indicates required
                  </Typography>
                  <div className="mc-field-group">
                    <TextField
                      label="Email Address"
                      type="email"
                      name="EMAIL"
                      className="required email"
                      id="mce-EMAIL"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      fullWidth
                      variant="outlined"
                      sx={{
                        "& .MuiInputBase-input": {
                          color: "black", // Force black text
                        },
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: "white", // White background
                          "& fieldset": {
                            borderColor: "#ccc", // Default border
                          },
                          "&:hover fieldset": {
                            borderColor: "#888", // Hover border
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "primary.main", // Focused border
                          },
                        },
                      }}
                    />
                  </div>
                  <div
                    id="mce-responses"
                    className="clear foot"
                    style={{ display: responseMessage ? "block" : "none" }}
                  >
                    <Typography
                      sx={{ mt: 1, color: isError ? "error.main" : "success.main" }}
                    >
                      {responseMessage}
                    </Typography>
                  </div>
                  <div
                    aria-hidden="true"
                    style={{ position: "absolute", left: "-5000px" }}
                  >
                    <input
                      type="text"
                      name="b_00ac2b75d1740071c7b3b16f1_e85ec95097"
                      tabIndex={-1}
                      value=""
                    />
                  </div>
                  <div className="clear foot">
                    <Button
                      type="submit"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      variant="contained"
                      color="white"
                      className="text-primary"
                      sx={{ mt: 1 }}
                    >
                      Subscribe
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </Grid>

          <Grid item size={{ md: 4 }} sx={{ px: 3 }} className="full-block">
            <Box className="scan">
              <Typography> ( And ) </Typography>
              <Box className="scanDiv">
                <Typography>Whatsapp</Typography>
                <Box component="img" src={whatsappScanImage} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default JoinOurCommunication;