import {  Box } from "@mui/material";
import ReactAudioPlayer from 'react-audio-player';
import { useAppSettings } from "../../contexts/AppSettingsContext";

function AudioGodSong() {

  const {
    PrayerSong,
  } = useAppSettings() || {};

  return (
    <Box className="audio-god-song">

<ReactAudioPlayer 
  src={PrayerSong}
  controls
  controlsList="nodownload noplaybackrate" 
/>

    </Box>
  )
}

export default AudioGodSong