import axios from "axios";
import { data } from "react-router-dom";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, 
});

const apiService = {
  getCms: () => api.get("/cms"),
  getCmsBySlug: (slug) => api.get(`/cms/${slug}`),
  getSliders: () => api.get("/sliders"),
  getPhotoGalleries: () => api.get("/photo-galleries"),
  getApplicationSettings: () => api.get("/applicationSettings"),
  getTeamCategories: () => api.get("/team-categories"),
  getTeams: (categoryId) => api.get(`/teams/${categoryId}`),
  getDeepamData: () => api.get("/clienteles/deepam"), 
  getPujas: () => api.get("/pujas"),
  getEvents: () => api.get("/event-categories"), // Get event categories
  getEventCategories: () => api.get("/event-categories"), // Add this to avoid the naming issue

  createTempleTour: (data) => api.post("/templeTours", data),
  createPujaRequest: (data) => api.post("/pujaOrders", data),
  getPoojaDetails: (data) => api.post("check-puja-order",data),
  createChangePujaRequest: (data) => api.post("/change-puja-order", data),
  cancelPoojaRequest: (data) => api.post("/cancel-puja-order", data),


  getHalls: () => api.get("/halls"),
  getHallEventTypes: () => api.get("/hallEventTypes"),

  createHallBookingRequest: (data) => api.post("/hallOrders", data),
  getHallBookingDetails: (data) => api.post("/check-hall-order", data),
  createChangeHallRequest: (data) => api.post("/change-hall-order", data),
  cancelHallRequest: (data) => api.post("/cancel-hall-order",data),
};

export default apiService;
