import React from "react";
import { Box, Link } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

function CheckAvailability({ url, type }) {
  const typeUrl = type ? `${url}?type=${encodeURIComponent(type)}` : url;

  return (
    <Box className="check-hall-availability" sx={{ py: 3 }}>
      <Link component="a" href={typeUrl} target="_blank">
        <CalendarMonthIcon /> Check {type ? `${type} ` : ""} Availability
      </Link>
    </Box>
  );
}

export default CheckAvailability;
