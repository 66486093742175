import * as React from "react";
import InnerLayout from "../../layouts/InnerLayout";
import InnerBanner from "../../images/inner-banner.jpg";
import Grid from "@mui/material/Grid2";
import { Box, Typography, Container } from "@mui/material";
import CallforAction from "../../components/call_for_action/CallForActtion.js";
import TourRequestForm from "./TourRequestForm.js";

function TempleToursRequest() {
  return (
    <InnerLayout>
      {/* Banner Section */}
      <Box component="section" className="inner-banner" sx={{ p: 0 }}>
        <Box component="img" className="banner-pic" src={InnerBanner} alt="Inner Banner" />
        <Box className="inner-text">
          <Container maxWidth="xl">
            <Grid
              container
              spacing={2}
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Grid item size={{md:6}}>
                <Typography
                  component="h1"
                  variant="h1"
                  className="section-title text-white"
                >
                  Temple Tours Request
                </Typography>
              </Grid>
              <Grid item md={6}>
                <CallforAction />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>

      {/* Information Section */}
      <Box component="section">
        <Container maxWidth="xl">
    

          {/* Form Section */}
     

          <Box sx={{ mt: 3 }}>
            <TourRequestForm />
          </Box>

          
        </Container>
      </Box>
    </InnerLayout>
  );
}

export default TempleToursRequest;
