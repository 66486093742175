import React, { useEffect, useState } from "react";
import apiService from "../../services/api";
import InnerLayout from "../../layouts/InnerLayout";
import InnerBanner from "../../images/inner-banner.jpg";
import CallforAction from "../../components/call_for_action/CallForActtion.js";
import {
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  FormControlLabel,
  Checkbox,
  TextField,
  RadioGroup,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Button,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TermsConditions from "../puja_request/TermsConditions.js";
import CheckAvailability from "../../components/check-availability/CheckAvailability.js";
import CircularProgress from "@mui/material/CircularProgress";

// Validation schema with Yup
const schema = yup
  .object({
    first_name: yup.string().required("First Name is required"),
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    mobile: yup.string().required("Mobile is required"),
    address: yup.string().nullable(),
    country: yup.string().nullable(),
    state: yup.string().nullable(),
    city: yup.string().nullable(),
    pincode: yup.string().nullable(),
    hall_event_type_id: yup
      .string()
      .required("Event Type is required"),
    other_event_type: yup
      .string()
      .when("hall_event_type_id", {
        is: (val) => val === "5",
        then: (schema) => schema.required("Please specify the event type"),
        otherwise: (schema) => schema.nullable(),
      }),
    start_time: yup.string().required("Start Time is required"),
    duration: yup.string().required("Duration is required"),
    community_name: yup.string().nullable(),
  })
  .required();

function SocialHallBooking() {
  const [halls, setHalls] = useState([]);
  const [selectedHalls, setSelectedHalls] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [eventType, setEventType] = useState("");
  const [eventTypes, setEventTypes] = useState([]);
  const [eventDuration, setEventDuration] = useState("");
  const [hallSelectionError, setHallSelectionError] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    apiService.getHalls().then((response) => {
      if (response.data.success) {
        setHalls(response.data.data);
      }
    });
  }, []);

  useEffect(() => {
    apiService.getHallEventTypes().then((response) => {
      if (response.data.success) {
        setEventTypes(response.data.data);
      }
    });
  }, []);

  const handleHallSelection = (hall, hours) => {
    setSelectedHalls((prevSelectedHalls) => {
      const exists = prevSelectedHalls.find((h) => h.id === hall.id);
      if (exists) {
        if (hall.name === "Classroom" && hours !== undefined) {
          return prevSelectedHalls.map((h) =>
            h.id === hall.id ? { ...h, hours: Math.max(1, Math.min(6, hours)) } : h
          );
        }
        return prevSelectedHalls.filter((h) => h.id !== hall.id);
      } else {
        const newHall = hall.name === "Classroom"
          ? { ...hall, hours: 1, selected_addons: hall.addons.filter((addon) => addon.default === 1) }
          : { ...hall, selected_addons: hall.addons.filter((addon) => addon.default === 1) };
        return [...prevSelectedHalls, newHall];
      }
    });
  };

  const handleAddonSelection = (hallId, addon, hours) => {
    setSelectedHalls((prevSelectedHalls) => {
      return prevSelectedHalls.map((hall) => {
        if (hall.id === hallId) {
          const exists = hall.selected_addons.find((a) => a.id === addon.id);
          
          if (exists) {
            if (addon.name === "Pre-Event Prep Time") {
              if (hours === undefined) {
                return {
                  ...hall,
                  selected_addons: hall.selected_addons.filter((a) => a.id !== addon.id),
                };
              }
              return {
                ...hall,
                selected_addons: hall.selected_addons.map((a) =>
                  a.id === addon.id ? { ...a, hours: Math.max(1, Math.min(6, hours)) } : a
                ),
              };
            }
            return {
              ...hall,
              selected_addons: hall.selected_addons.filter((a) => a.id !== addon.id),
            };
          } else {
            const newAddon = addon.name === "Pre-Event Prep Time"
              ? { ...addon, hours: 1 }
              : addon;
            return {
              ...hall,
              selected_addons: [...hall.selected_addons, newAddon],
            };
          }
        }
        return hall;
      });
    });
  };

  const getHallCost = (hall) => {
    if (!selectedDate) return 0;
    const day = new Date(selectedDate).toLocaleString("en-US", { weekday: "long" }).toLowerCase();
    const baseCost = eventType === "community" && eventDuration === "three-day"
      ? hall[`${day}_three_day_cost`] || 0
      : hall[`${day}_cost`] || 0;
    const hoursMultiplier = hall.hours && hall.name === "Classroom" ? hall.hours : 1;
    return baseCost * hoursMultiplier;
  };

  const getAddonCost = (addon) => {
    if (!selectedDate) return 0;
    const day = new Date(selectedDate)
      .toLocaleString("en-US", { weekday: "long" })
      .toLowerCase();
    const baseCost = addon.costs[`${day}_cost`] || 0;
    const multiplier = eventType === "community" && eventDuration === "three-day" ? 3 : 1;
    const hoursMultiplier = addon.hours && addon.name === "Pre-Event Prep Time" 
      ? addon.hours 
      : 1;
    return baseCost * multiplier * hoursMultiplier;
  };

  const filteredHalls =
    selectedDate &&
      (eventType === "personal" || (eventType === "community" && eventDuration))
    ? halls.filter((hall) => getHallCost(hall) > 0)
    : [];

  const totalCost = selectedHalls.reduce((sum, hall) => {
    const hallCost = getHallCost(hall);
    const addonsCost = hall.selected_addons.reduce(
      (addonSum, addon) => addonSum + getAddonCost(addon),
      0
    );
    return sum + hallCost + addonsCost;
  }, 0);

  const onSubmit = async (data) => {
    if (selectedHalls.length === 0) {
      setHallSelectionError("Please select at least one hall.");
      return;
    }

    if (eventType === "community" && !data.community_name) {
      setHallSelectionError("Community Name is required.");
      return;
    }

    setLoading(true); // Start loading

    try {
      const requestData = {
        ...data,
        type_of_event: eventType,
        date_of_event: selectedDate,
        event_duration: eventDuration || "one-day", // Ensure event_duration is sent
        selected_halls: selectedHalls.map((hall) => {
          const hallData = {
            hall_id: hall.id,
            hall_cost: getHallCost(hall),
          };
          
          // Add hours for Classroom if selected
          if (hall.name === "Classroom" && hall.hours) {
            hallData.hours = hall.hours;
          }

          // Add addons with hours for Pre-Event Prep Time
          hallData.addons = hall.selected_addons.map((addon) => {
            const addonData = {
              addon_id: addon.id,
              addon_cost: getAddonCost(addon),
            };
            
            if (addon.name === "Pre-Event Prep Time" && addon.hours) {
              addonData.hours = addon.hours;
            }
            
            return addonData;
          });

          return hallData;
        }),
      };

      const response = await apiService.createHallBookingRequest(requestData);
      if (response.data.success) {
        sessionStorage.setItem(
          "successPageState",
          JSON.stringify({
            type: "social-hall-booking",
            requestId: response.data.data.hall_request_id,
          })
        );

        navigate("/success", {
          state: {
            type: "social-hall-booking",
            requestId: response.data.data.hall_request_id,
          },
        });

        reset();
      }
    } catch (error) {
      console.error("Error submitting booking request:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <InnerLayout>
      <Box component="section" className="inner-banner" sx={{ p: 0 }}>
        <Box component="img" className="banner-pic" src={InnerBanner} />
        <Box className="inner-text">
          <Container maxWidth="xl">
            <Grid
              container
              spacing={2}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item size={{ md: 6 }}>
                <Typography
                  component="h1"
                  variant="h1"
                  className="section-title text-white"
                >
                  Social Hall Booking
                </Typography>
              </Grid>
              <Grid item size={{ md: 6 }}>
                <CallforAction />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>

      <Box className="social-hall-booking" component="section">
        <Container maxWidth="xl">
          <form onSubmit={handleSubmit(onSubmit)}>
            {currentStep === 1 && (
              <Card className="custom-card">
                <CardContent>
                  <Box>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        value={eventType}
                        onChange={(e) => {
                          const selectedType = e.target.value;
                          setEventType(selectedType);
                          setEventDuration("");
                          setSelectedHalls([]);
                        }}
                      >
                        <FormControlLabel value="personal" control={<Radio />} label="Personal" />
                        <FormControlLabel value="community" control={<Radio />} label="Community" />
                      </RadioGroup>
                    </FormControl>

                    {eventType === "community" && (
                        <Box className="community-link-address">
                        <Typography sx={{ my:2}}>Please Select the Event Type</Typography>

                      <FormControl component="fieldset" error={!!errors.event_duration}>
                        <RadioGroup row onChange={(e) => setEventDuration(e.target.value)}>
                          <FormControlLabel value="one-day" control={<Radio />} label="One Day Event" />
                          <FormControlLabel value="three-day" control={<Radio />} label="Three Day Event" />
                        </RadioGroup>
                        {errors.event_duration && <FormHelperText>{errors.event_duration.message}</FormHelperText>}
                      </FormControl>
                      </Box>
                    )}

                    <CheckAvailability url="https://www.trumba.com/calendars/temple-social-hall-booking" type="Hall" />
                    <TextField
                      type="date"
                      label="Select Date"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      value={selectedDate}
                      onChange={(e) => setSelectedDate(e.target.value)}
                      onClick={(e) => e.target.showPicker && e.target.showPicker()}
                      sx={{ my: 3 }}
                    />

                    {selectedDate && (
                      <>
                        <Box className="hall-lists">
                          {filteredHalls.map((hall) => {
                            const selectedHall = selectedHalls.find((h) => h.id === hall.id);
                            const isChecked = !!selectedHall;
                            const hours = selectedHall?.hours || 1;

                            return (
                              <Box className="hall-list-item" key={hall.id}>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={isChecked}
                                          onChange={() => handleHallSelection(hall)}
                                        />
                                      }
                                      label={hall.name}
                                    />
                                    
                                    {hall.name === "Classroom" && isChecked && (
                                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Button
                                          size="small"
                                          variant="outlined"
                                          onClick={() => handleHallSelection(hall, hours - 1)}
                                          disabled={hours <= 1}
                                        >
                                          -
                                        </Button>
                                        <Typography>
                                          {hours} hr
                                        </Typography>
                                        <Button
                                          size="small"
                                          variant="outlined"
                                          onClick={() => handleHallSelection(hall, hours + 1)}
                                          disabled={hours >= 6}
                                        >
                                          +
                                        </Button>
                                      </Box>
                                    )}
                                  </Box>
                                  <Typography
                                    variant="body2"
                                    className="font-700"
                                  >
                                    Cost:{" "}
                                    <Typography
                                      className="font-700 text-primary"
                                      component="span"
                                    >
                                      {getHallCost(selectedHall || hall)}
                                    </Typography>
                                  </Typography>
                                </Box>

                                {isChecked && hall.addons.length > 0 && (
                                  <Box className="addon-list" sx={{ mt: 2 }}>
                                    <Typography
                                      variant="subtitle1"
                                      className="font-700"
                                    >
                                      Add-ons:
                                    </Typography>
                                    {hall.addons
                                      .filter((addon) => getAddonCost(addon) > 0)
                                      .map((addon) => {
                                        const selectedAddon = selectedHalls
                                          .find((h) => h.id === hall.id)
                                          ?.selected_addons.find((a) => a.id === addon.id);
                                        const isAddonChecked = !!selectedAddon;
                                        const addonHours = selectedAddon?.hours || 1;

                                        return (
                                          <Box
                                            className="addon-list-item"
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            key={addon.id}
                                          >
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={isAddonChecked}
                                                    onChange={() => handleAddonSelection(hall.id, addon)}
                                                    disabled={addon.default === 1}
                                                  />
                                                }
                                                label={addon.name}
                                              />
                                              
                                              {addon.name === "Pre-Event Prep Time" && isAddonChecked && (
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                  <Button
                                                    size="small"
                                                    variant="outlined"
                                                    onClick={() => handleAddonSelection(hall.id, addon, addonHours - 1)}
                                                    disabled={addonHours <= 1}
                                                  >
                                                    -
                                                  </Button>
                                                  <Typography>
                                                    {addonHours} hr
                                                  </Typography>
                                                  <Button
                                                    size="small"
                                                    variant="outlined"
                                                    onClick={() => handleAddonSelection(hall.id, addon, addonHours + 1)}
                                                    disabled={addonHours >= 6}
                                                  >
                                                    +
                                                  </Button>
                                                </Box>
                                              )}
                                            </Box>
                                            
                                            <Typography className="font-700" variant="body2">
                                              Cost: <Typography component="span" className="font-700 text-primary">
                                                {getAddonCost(selectedAddon || addon)}
                                              </Typography>
                                            </Typography>
                                          </Box>
                                        );
                                      })}
                                  </Box>
                                )}
                              </Box>
                            );
                          })}
                        </Box>

                        <Box className="total-cost-block text-center">
                          <Typography
                            variant="h5"
                            className="font-700"
                            sx={{ my: 3 }}
                          >
                            Total Cost:{" "}
                            <Typography variant="span" className="text-primary">
                              {" "}
                              {totalCost}
                            </Typography>
                          </Typography>
                        </Box>

                        <Box className="total-button text-center">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setCurrentStep(2)}
                            disabled={selectedHalls.length === 0}
                          >
                            Next
                          </Button>
                        </Box>
                      </>
                    )}
                  </Box>
                </CardContent>
              </Card>
            )}

            {currentStep === 2 && (
              <Grid
                container
                spacing={2}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item size={{ xs: 12, md: 8 }} order={{ xs: 2, md: 1 }}>
                  <Box className="custom-card">
                    <Typography
                      variant="h4"
                      className="text-primary font-700"
                      gutterBottom
                    >
                      Hall Booking Form
                    </Typography>

                    <Grid container spacing={2}>
                      {eventType === "community" && (
                        <Grid item size={{ xs: 12, md: 6 }}>
                          <TextField
                            label="Community Name"
                            variant="outlined"
                            fullWidth
                            {...register("community_name")}
                            error={!!errors.community_name || !!hallSelectionError}
                            helperText={errors.community_name?.message || hallSelectionError}
                            sx={{ mb: 3 }}
                          />
                        </Grid>
                      )}
                      <Grid item size={{ xs: 12, md: 6 }}>
                        <TextField
                          label={eventType === "community" ? "Contact Person Name" : "First Name"}
                          variant="outlined"
                          fullWidth
                          {...register("first_name")}
                          error={!!errors.first_name}
                          helperText={errors.first_name?.message}
                          sx={{ mb: 3 }}
                        />
                      </Grid>

                      {eventType !== "community" && (
                        <Grid item size={{ xs: 12, md: 6 }}>
                          <TextField
                            label="Last Name"
                            variant="outlined"
                            fullWidth
                            {...register("last_name")}
                            sx={{ mb: 3 }}
                          />
                        </Grid>
                      )}

                      <Grid item size={{ xs: 12, md: 6 }}>
                        <TextField
                          label="Email"
                          variant="outlined"
                          fullWidth
                          {...register("email")}
                          error={!!errors.email}
                          helperText={errors.email?.message}
                          sx={{ mb: 3 }}
                        />
                      </Grid>
                      <Grid item size={{ xs: 12, md: 6 }}>
                        <TextField
                          label="Mobile"
                          variant="outlined"
                          fullWidth
                          {...register("mobile")}
                          error={!!errors.mobile}
                          helperText={errors.mobile?.message}
                          sx={{ mb: 3 }}
                        />
                      </Grid>
                      <Grid item size={{ xs: 12, md: 6 }}>
                        <TextField
                          label="Address"
                          fullWidth
                          {...register("address")}
                          error={!!errors.address}
                          helperText={errors.address?.message}
                          sx={{ mb: 3 }}
                        />
                      </Grid>
                      <Grid item size={{ xs: 12, md: 6 }}>
                        <TextField
                          label="Country"
                          fullWidth
                          {...register("country")}
                          error={!!errors.country}
                          helperText={errors.country?.message}
                          sx={{ mb: 3 }}
                        />
                      </Grid>
                      <Grid item size={{ xs: 12, md: 6 }}>
                        <TextField
                          label="State"
                          fullWidth
                          {...register("state")}
                          error={!!errors.state}
                          helperText={errors.state?.message}
                          sx={{ mb: 3 }}
                        />
                      </Grid>
                      <Grid item size={{ xs: 12, md: 6 }}>
                        <TextField
                          label="City"
                          fullWidth
                          {...register("city")}
                          error={!!errors.city}
                          helperText={errors.city?.message}
                          sx={{ mb: 3 }}
                        />
                      </Grid>
                      <Grid item size={{ xs: 12, md: 6 }}>
                        <TextField
                          label="Pincode"
                          fullWidth
                          {...register("pincode")}
                          error={!!errors.pincode}
                          helperText={errors.pincode?.message}
                          sx={{ mb: 3 }}
                        />
                      </Grid>

                      <Grid item size={{ xs: 12, md: 6 }}>
                        <FormControl fullWidth sx={{ mb: 3 }} error={!!errors.hall_event_type_id}>
                          <InputLabel>Event Type</InputLabel>
                          <Select {...register("hall_event_type_id")}>
                            {eventTypes.map((event) => (
                              <MenuItem key={event.id} value={event.id.toString()}>
                                {event.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.hall_event_type_id && (
                            <FormHelperText>{errors.hall_event_type_id.message}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      {watch("hall_event_type_id") === "5" && (
                        <Grid item size={{ xs: 12, md: 6 }}>
                          <TextField
                            label="Other Event Type"
                            fullWidth
                            {...register("other_event_type")}
                            error={!!errors.other_event_type}
                            helperText={errors.other_event_type?.message}
                            sx={{ mb: 3 }}
                          />
                        </Grid>
                      )}

                      <Grid item size={{ xs: 12, md: 6 }}>
                        <TextField
                          label="Alternate Date Of Event"
                          type="date"
                          variant="outlined"
                          onClick={(e) => e.target.showPicker && e.target.showPicker()}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          {...register("alternate_date_of_event")}
                          sx={{ mb: 3 }}
                        />
                      </Grid>

                      <Grid item size={{ xs: 12, md: 6 }}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                          <InputLabel>Start Time</InputLabel>
                          <Select {...register("start_time")}>
                            {Array.from({ length: 15 }, (_, index) => {
                              const hour = index + 5;
                              const period = hour < 12 ? "AM" : "PM";
                              const formattedHour = hour > 12 ? hour - 12 : hour;
                              return (
                                <MenuItem key={hour} value={`${hour}:00`}>
                                  {`${formattedHour}:00 ${period}`}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {errors.start_time && (
                            <FormHelperText error>
                              {errors.start_time?.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item size={{ xs: 12, md: 6 }}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                          <InputLabel>Hours/Duration</InputLabel>
                          <Select
                            {...register("duration")}
                            onChange={(e) => setValue("duration", e.target.value)}
                          >
                            {Array.from({ length: 24 }, (_, index) => {
                              const duration = index + 1;
                              return (
                                <MenuItem key={duration} value={duration}>
                                  {`${duration} ${duration > 1 ? "hours" : "hour"}`}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {errors.duration && (
                            <FormHelperText error>
                              {errors.duration?.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item size={{ xs: 12, md: 12 }}>
                        <TermsConditions type="socialhall" />
                      </Grid>

                      <Grid item size={{ xs: 12, md: 12 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={loading} // Disable button while loading
                        >
                          {loading ? <CircularProgress size={24} /> : "Submit"}
                        </Button>

                        <Button
                          variant="outlined"
                          sx={{ ml: 2 }}
                          onClick={() => setCurrentStep(1)}
                          disabled={loading} // Disable button while loading
                        >
                          Back
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Grid
                  className="sticky"
                  size={{ xs: 12, md: 4 }}
                  order={{ xs: 1, md: 2 }}
                  sx={{ position: "sticky", top: "10px", alignSelf: "start" }}
                >
                  <Box>
                    <Card className="custom-card no-shadow">
                      <Typography
                        variant="h4"
                        className="text-primary font-700 border-title"
                      >
                        Selected Halls
                      </Typography>

                      <Box className="selected-halls">
                        <Box sx={{ my: 2 }} className="selected-block">
                          {selectedHalls.map((hall) => (
                            <Box
                              key={hall.id}
                              sx={{ mb: 1 }}
                              className="selected-hall-item"
                            >
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                className="items-list"
                              >
                                <Typography variant="p" component="span">
                                  <CheckCircleIcon /> {hall.name}
                                  {hall.name === "Classroom" && 
                                    ` (${hall.hours} hour${hall.hours > 1 ? 's' : ''})`}
                                </Typography>
                                <Typography
                                  variant="p"
                                  component="span"
                                  className="font-700 text-primary"
                                >
                                  $ {getHallCost(hall)}
                                </Typography>
                              </Box>

                              {hall.selected_addons.length > 0 && (
                                <Box>
                                  <Typography
                                    variant="h6"
                                    className="font-700 text-primary"
                                    sx={{ mt: 1, mb: 0 }}
                                  >
                                    Add-ons:
                                  </Typography>
                                  {hall.selected_addons.map((addon) => (
                                    <Box key={addon.id}>
                                      <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        className="items-list"
                                      >
                                        <Typography
                                          variant="p"
                                          component="span"
                                          className="addon-name"
                                        >
                                          {addon.name}
                                          {addon.name === "Pre-Event Prep Time" && 
                                            ` (${addon.hours} hour${addon.hours > 1 ? 's' : ''})`}
                                        </Typography>
                                        <Typography
                                          variant="p"
                                          component="span"
                                          className="font-700"
                                        >
                                          $ {getAddonCost(addon)}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  ))}
                                </Box>
                              )}
                            </Box>
                          ))}
                        </Box>

                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          className="grand-total"
                        >
                          <Typography
                            variant="h5"
                            component="span"
                            className="font-700"
                          >
                            Total:
                          </Typography>
                          <Typography
                            variant="h5"
                            component="span"
                            className="font-700 text-primary"
                          >
                            ${totalCost}
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Box>
                </Grid>
              </Grid>
            )}
          </form>
        </Container>
      </Box>
    </InnerLayout>
  );
}

export default SocialHallBooking;