import React, { useEffect, useState } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import apiService from "../../services/api.js";
import { Container, Typography, Box, Link } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CallforAction from "../../components/call_for_action/CallForActtion.js";
import InnerLayout from "../../layouts/InnerLayout.js";
import InnerBanner from "../../images/inner-banner.jpg";
import EventCard from "../../components/EventCard";
import DateRangeIcon from "@mui/icons-material/DateRange";
import LoadingIcon from "../../components/LoadingIcon";
import htmlConvert from "../../themeSettings/htmlConvert.js";

function EventDetails() {
  const { slug } = useParams();
  const [event, setEvent] = useState(null);
  const [relatedEvents, setRelatedEvents] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    apiService
      .getEventCategories()
      .then((response) => {
        if (response.data.success && response.data.data) {
          const foundEvent = response.data.data
            .flatMap((category) => category.events)
            .find((event) => event.slug === slug);

          if (foundEvent) {
            setEvent(foundEvent);
            setBreadcrumbs([
              { label: "Home", path: "/" },
              { label: "Events", path: "/events" },

              { label: foundEvent.title, path: `/events/${slug}` },
            ]);

            const related = response.data.data
              .flatMap((category) => category.events)
              .filter(
                (eventItem) =>
                  eventItem.category_name === foundEvent.category_name &&
                  eventItem.slug !== slug
              );

            setRelatedEvents(related);
          } else {
            setError("Event not found.");
          }
        } else {
          setError("Event categories could not be loaded.");
        }
        setLoading(false);
      })
      .catch((err) => {
        setError("Failed to load event details.");
        setLoading(false);
      });
  }, [slug]);

  if (loading) return <LoadingIcon />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <InnerLayout>
      <Box component="section" className="inner-banner">
        <Box
          component="img"
          className="banner-pic"
          src={InnerBanner}
          alt={event.title}
        />
        <Box className="inner-text">
          <Container maxWidth="xl">
            <Grid
              container
              spacing={2}
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Grid
                item
                size={{ xs: 12, md: 6 }}
                className="page-title-section"
              >
                {/* Dynamic Breadcrumbs */}
                <Breadcrumbs
                  aria-label="breadcrumb"
                  className="text-white breadcrumb"
                >
                
                  {breadcrumbs.map((breadcrumb, index) => (
                    <Link
                      key={index}
                      underline="hover"
                      color={
                        index === breadcrumbs.length - 1
                          ? "textWhite"
                          : "inherit"
                      }
                      component={RouterLink}
                      to={breadcrumb.path}
                      style={
                        index === breadcrumbs.length - 1
                          ? { color: "white" }
                          : {}
                      }
                    >
                      {breadcrumb.label}
                    </Link>
                  ))}
                </Breadcrumbs>

                <Typography
                  component="h1"
                  variant="h1"
                  className="section-title text-white"
                >
                  {event.title}
                </Typography>
              </Grid>
              <Grid item size={{ xs: 12, md: 6 }}>
                <CallforAction />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>

      <Box component="section">
        <Container maxWidth="xl">
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: "space-between", alignItems: "start" }}
          >
            <Grid item size={{ xs: 12, md: 7 }}>
              <Box component="img" src={event.image} className="w-100 mb-30" />

              <Typography
                variant="h2"
                className="text-primary event-details-title"
              >
                {event.title}
              </Typography>

              <Box className="detail-date">
                <DateRangeIcon />
                {new Date(event.start_date_time).toLocaleString()} -
                {new Date(event.end_date_time).toLocaleString()}
              </Box>

              <Box component="div" className="section-content">
                {event.short_description ? (
                  htmlConvert({ htmlContent: event.short_description })
                ) : (
                  <Typography variant="h5" textAlign="center" sx={{ mt: 4 }}>
                    No Description Found
                  </Typography>
                )}
              </Box>

              <Box component="div" className="section-content mt-30">
                {event.description ? (
                  htmlConvert({ htmlContent: event.description })
                ) : (
                  <Typography variant="h5" textAlign="center" sx={{ mt: 4 }}>
                    No Description Found
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item size={{ xs: 12, md: 4 }}>
              <Box className="related-events">
                <Typography
                  variant="h5"
                  className="text-primary font-700"
                  gutterBottom
                >
                  Related Events
                </Typography>
                {relatedEvents.length > 0 ? (
                  <Box spacing={2}>
                    {relatedEvents.map((relatedEvent, index) => (
                      <Box className="related-events-lists" md={4} key={index}>
                        <EventCard event={relatedEvent} />
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Typography>No related events found.</Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </InnerLayout>
  );
}

export default EventDetails;
