import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import EventCard from "../../components/EventCard";
import InnerLayout from "../../layouts/InnerLayout";
import { Container, Box, Tabs, Tab, Typography } from "@mui/material";
import InnerBanner from "../../images/inner-banner.jpg";
import CallforAction from "../../components/call_for_action/CallForActtion";
import apiService from "../../services/api.js"; // Ensure this path is correct

const parseDate = (dateString) => {
  return new Date(dateString);
};

const filterEventsByCategory = (events, category) => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const tomorrow = new Date(currentDate);
  tomorrow.setDate(currentDate.getDate() + 1);

  return events.filter((event) => {
    const eventDate = parseDate(event.start_date_time);

    if (category === "upcoming") {
      return eventDate >= tomorrow;
    } else if (category === "ongoing") {
      return eventDate >= currentDate && eventDate < tomorrow;
    } else if (category === "past") {
      return eventDate < currentDate;
    }
    return false;
  });
};

function Events() {
  const [value, setValue] = useState(0);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    apiService
      .getEvents()
      .then((response) => {
        if (response.data.success && response.data.data.length > 0) {
          // Include category name in each event
          const allEvents = response.data.data.flatMap((category) =>
            category.events.map((event) => ({
              ...event,
              category_name: category.name, // Add the category name to each event
            }))
          );
          setEvents(allEvents);
        } else {
          setEvents([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        setError("Failed to load events.");
        setLoading(false);
      });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (loading) return <Typography>Loading events...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  const upcomingEvents = filterEventsByCategory(events, "upcoming");
  const ongoingEvents = filterEventsByCategory(events, "ongoing");
  const pastEvents = filterEventsByCategory(events, "past");

  return (
    <InnerLayout>
      <Box component="section" className="inner-banner">
        <Box
          component="img"
          src={InnerBanner}
          className="banner-pic"
          alt="Events Banner"
        />
        <Box className="inner-text">
          <Container maxWidth="xl">
            <Grid
              container
              spacing={2}
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Grid item md={6}>
                <Typography
                  component="h1"
                  variant="h1"
                  className="section-title text-white"
                >
                  Events
                </Typography>
              </Grid>
              <Grid item md={6}>
                <CallforAction />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>


<Box component="section" className="event-page">
      <Container maxWidth="xl">
        <Box sx={{ flexGrow: 1, display: "flex", height: "auto" }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Event Categories"
            sx={{ borderRight: 1, borderColor: "divider", minWidth: 200 }}
          >
            <Tab label="All Events" />
            <Tab label="Upcoming Events" />
            <Tab label="Ongoing Events" />
            <Tab label="Past Events" />
          </Tabs>

          <Box sx={{ flexGrow: 1, p: 3 }}>
            {value === 0 && <EventGrid events={events} />}
            {value === 1 && <EventGrid events={upcomingEvents} />}
            {value === 2 && <EventGrid events={ongoingEvents} />}
            {value === 3 && <EventGrid events={pastEvents} />}
          </Box>
        </Box>
      </Container>

      </Box>


    </InnerLayout>
  );
}

const EventGrid = ({ events }) => (
  <Grid container spacing={2}>
    {events.length > 0 ? (
      events.map((event, index) => (
        <Grid item size={{md:4}}  key={index}>
          <EventCard event={event} />
        </Grid>
      ))
    ) : (
      <Typography>No events available</Typography>
    )}
  </Grid>
);

export default Events;
