import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  MenuItem,
} from "@mui/material";
import apiService from "../../services/api";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import TermsConditions from "../puja_request/TermsConditions";
// Time Slots (5 AM - 7 PM)
const timeSlots = [
  "05:00 AM",
  "06:00 AM",
  "07:00 AM",
  "08:00 AM",
  "09:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "01:00 PM",
  "02:00 PM",
  "03:00 PM",
  "04:00 PM",
  "05:00 PM",
  "06:00 PM",
  "07:00 PM",
];

// ✅ Validation Schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),

  tour_date: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .required("Tour Date is required"),

  tour_time: Yup.string()
    .required("Tour Time is required")
    .oneOf(timeSlots, "Invalid tour time"),

  alternate_tour_date: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value)),

  alternate_tour_time: Yup.string()
    .nullable()
    .oneOf(timeSlots, "Invalid alternate tour time"),

  email: Yup.string().email("Invalid email").required("Email is required"),

  mobile: Yup.string().nullable(), // ✅ No length restriction for US numbers

  total_visitors: Yup.number()
    .nullable()
    .transform((value, originalValue) =>
      originalValue === "" ? null : Number(originalValue)
    )
    .typeError("Total Visitors must be a number")
    .positive("Total Visitors must be positive"),

  age_range_of_group: Yup.string().nullable(),

  last_visit_to_temple: Yup.boolean().oneOf([true, false], "Invalid selection"),

  comment: Yup.string().nullable(),

  terms_conditions: Yup.boolean().oneOf([true], "You must accept terms"),
});

const TourRequestForm = () => {
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      setMessage(null);
      setError(null);

      console.log("Form Data:", data); // Check the form data

      // Call the createTempleTour method to submit the data
      const response = await apiService.createTempleTour(data);

      console.log("API Response:", response); // Check the response from API

      if (response.data.success) {
        setMessage("Tour request submitted successfully!");
        reset(); // Reset form after success

        // Pass data to the success page
        const { tour_request_id } = response.data.data;

        sessionStorage.setItem(
          "successPageState",
          JSON.stringify({
            type: "temple-tour",
            requestId: tour_request_id,
          })
        );

        navigate("/success", {
          state: {
            type: "temple-tour",
            requestId: tour_request_id,
          },
        });
      }
    } catch (err) {
      console.error("Error:", err); // Log any error
      setError("Something went wrong. Please try again.");
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      className="custom-card"
      sx={{ mx: "auto" }}
    >
      <Typography variant="h4" className="text-primary font-700" gutterBottom>
        Tour Request Form
      </Typography>

      {message && <Typography color="success.main">{message}</Typography>}
      {error && <Typography color="error.main">{error}</Typography>}

      <Grid container spacing={2}>
        {/* Name */}
        <Grid item size={{ xs: 12, md: 4 }}>
          <TextField
            fullWidth
            label="Name of group/individual"
            {...register("name")}
            error={!!errors.name}
            helperText={errors.name?.message}
            sx={{ mb: 2 }}
          />
        </Grid>

        {/* Tour Date */}
        <Grid item size={{ xs: 12, md: 4 }}>
          <TextField
            fullWidth
            type="date"
            {...register("tour_date")}
            onClick={(e) => e.target.showPicker && e.target.showPicker()} 
            error={!!errors.tour_date}
            helperText={errors.tour_date?.message}
            sx={{ mb: 2 }}
          />
        </Grid>

        {/* Tour Time */}
        <Grid item size={{ xs: 12, md: 4 }}>
          <TextField
            select
            fullWidth
            label="Tour Time"
            {...register("tour_time")}
            error={!!errors.tour_time}
            helperText={errors.tour_time?.message}
            sx={{ mb: 2 }}
          >
            {timeSlots.map((time) => (
              <MenuItem key={time} value={time}>
                {time}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* Alternate Tour Date */}
        <Grid item size={{ xs: 12, md: 4 }}>
          <TextField
            fullWidth
            type="date"
            onClick={(e) => e.target.showPicker && e.target.showPicker()} 
            {...register("alternate_tour_date")}
            error={!!errors.alternate_tour_date}
            helperText={errors.alternate_tour_date?.message}
            sx={{ mb: 2 }}
          />
        </Grid>

        {/* Alternate Tour Time */}
        <Grid item size={{ xs: 12, md: 4 }}>
          <TextField
            select
            fullWidth
            label="Alternate Tour Time"
            {...register("alternate_tour_time")}
            error={!!errors.alternate_tour_time}
            helperText={errors.alternate_tour_time?.message}
            sx={{ mb: 2 }}
          >
            {timeSlots.map((time) => (
              <MenuItem key={time} value={time}>
                {time}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* Email */}
        <Grid item size={{ xs: 12, md: 4 }}>
          <TextField
            fullWidth
            type="email"
            label="Email"
            {...register("email")}
            error={!!errors.email}
            helperText={errors.email?.message}
            sx={{ mb: 2 }}
          />
        </Grid>

        {/* Mobile */}
        <Grid item size={{ xs: 12, md: 4 }}>
          <TextField
            fullWidth
            type="tel"
            label="Mobile"
            {...register("mobile")}
            error={!!errors.mobile}
            helperText={errors.mobile?.message}
            sx={{ mb: 2 }}
          />
        </Grid>

        {/* Total Visitors */}
        <Grid item size={{ xs: 12, md: 4 }}>
          <TextField
            fullWidth
            type="number"
            label="Count of people visiting as part of group"
            {...register("total_visitors")}
            error={!!errors.total_visitors}
            helperText={errors.total_visitors?.message}
            sx={{ mb: 2 }}
          />
        </Grid>

        {/* Age Range of Group */}
        <Grid item size={{ xs: 12, md: 4 }}>
          <TextField
            fullWidth
            label="Age range of group (saperated by ,)"
            {...register("age_range_of_group")}
            error={!!errors.age_range_of_group}
            helperText={errors.age_range_of_group?.message}
            sx={{ mb: 2 }}
          />
        </Grid>

        {/* Last Visit to Temple */}
        <Grid item size={{ xs: 12, md: 12 }}>
          <FormControlLabel
            control={<Checkbox {...register("last_visit_to_temple")} />}
            label="Last time visit to temple"
          />
          <Typography color="error.main">
            {errors.last_visit_to_temple?.message}
          </Typography>
        </Grid>

        {/* Comment */}
        <Grid item size={{ xs: 12, md: 12 }}>
          <TextField
            fullWidth
            multiline
            rows={3}
            label="Comment"
            {...register("comment")}
            error={!!errors.comment}
            helperText={errors.comment?.message}
            sx={{ mb: 2 }}
          />
        </Grid>

        {/* Terms & Conditions */}
        <Grid item size={{ xs: 12, md: 12 }}>
        <TermsConditions type="tour" />
          {/* <FormControlLabel
            control={<Checkbox {...register("terms_conditions")} />}
            label="I accept the terms & conditions"
          />
          <Typography color="error.main">
            {errors.terms_conditions?.message}
          </Typography> */}
        </Grid>

        {/* Submit Button */}
        <Grid item size={{ xs: 12, md: 12 }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            sx={{ mt: 2 }}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </Button>
        </Grid>
      </Grid>

      {/* <Box className="py-30">
        <Typography component="p">
          <Typography component="span" className="text-primary font-700">
            Note:
          </Typography>
          <br />
          1. No Temple tours will be conducted on
          <Typography component="span" className="text-primary">
            Saturdays & Sundays
          </Typography>
          until further notice. Sorry for any inconvenience.
          <br />
          2. Tours are conducted for
          <Typography component="span" className="text-primary">
            groups of 10 or more.
          </Typography>
        </Typography>
      </Box> */}
    </Box>
  );
};

export default TourRequestForm;
