import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import apiService from "../../services/api.js";
import EventCard from "../EventCard.js";
import godImage from "../../images/god.jpg";
import { Box, Link, Typography } from "@mui/material";

function EventsSlider() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    apiService
      .getEvents()
      .then((response) => {
        console.log("API Response:", response.data);

        if (response.data.success && Array.isArray(response.data.data)) {
          // Flatten all events and include category name
          const allEvents = response.data.data.flatMap(category =>
            category.events.map(event => ({
              ...event,
              category_name: category.name, // Attach category name to each event
            }))
          );

          // Filter events to only include upcoming ones
          const currentDate = new Date();
          const upcomingEvents = allEvents.filter(event => {
            const eventStartDate = new Date(event.start_date_time);
            return eventStartDate > currentDate;
          });

          setEvents(upcomingEvents);
        } else {
          setEvents([]);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching events:", error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Box>Loading events...</Box>;
  }

  // If there are no events, show the "Past Events" block only
  if (!events.length) {
    return (
      <Box className="static-event-card">
        <Box
          component="img"
          src={godImage}
          alt="Past Events"
          className="w-100 theme-radius"
        />
        <Typography>No upcoming events available</Typography>
        <Link href="/events" className="btn btn-primary">
          View Past Events
        </Link>
      </Box>
    );
  }

  const settings = {
    dots: events.length > 3,
    infinite: events.length > 3,
    arrows: events.length > 3,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          dots: false,
          infinite: events.length > 1,
          arrows: events.length > 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          dots: false,
          infinite: events.length > 1,
          arrows: events.length > 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {events.map((event, index) => (
        <Box key={index}>
          <EventCard event={event} />
        </Box>
      ))}
      {/* "Past Events" Slide as the Last Slide */}
      <Box key="static" className="static-event-card">
        <Box
          component="img"
          src={godImage}
          alt="Past Events"
          className="w-100 theme-radius"
        />
        <Link href="/events" className="btn btn-primary">
          View Past Events
        </Link>
      </Box>
    </Slider>
  );
}

export default EventsSlider;
