import React from "react";
import InnerLayout from "../../layouts/InnerLayout";
import InnerBanner from "../../images/inner-banner.jpg";
import Grid from "@mui/material/Grid2";
import { Box, Typography, Container, Button } from "@mui/material";
import CallforAction from "../../components/call_for_action/CallForActtion.js";
import { Cancel, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function PujaRequest() {
    const navigate = useNavigate();

    const handleNavigation = (location) => {
        localStorage.removeItem("cart");
        navigate(`/puja-details/${location}`);
    };

    return (
        <InnerLayout>
            <Box component="section" className="inner-banner" sx={{ p: 0 }}>
                <Box component="img" className="banner-pic" src={InnerBanner} />

                <Box className="inner-text">
                    <Container maxWidth="xl">
                        <Grid
                            container
                            spacing={2}
                            sx={{
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Grid item size={{ md: 6 }}>
                                <Typography
                                    component="h1"
                                    variant="h1"
                                    className="section-title text-white"
                                >
                                    Puja & Prayers
                                </Typography>
                            </Grid>
                            <Grid item size={{ md: 6 }}>
                                <CallforAction />
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>

            {/* Puja Request Section */}
            <Box component="section" className="specify-page min-height">
                <Container maxWidth="xl">
                    <Typography
                        component="p"
                        variant="body1"
                        sx={{ mb: 3, fontSize: "1.2rem" }}
                    >
                        Please specify where you want the puja.
                        <br />
                        (Please try to schedule at least 3-7 days before the event)
                    </Typography>

                    <Box sx={{ display: "flex", gap: 2 }} className="specify-page-buttons">
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ px: 4, py: 1 }}
                            onClick={() => handleNavigation("temple")}
                        >
                            Temple
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            sx={{ px: 4, py: 1 }}
                            onClick={() => handleNavigation("home")}
                        >
                            Home
                        </Button>
                    </Box>

                    <Box sx={{ display: "flex", gap: 2, mt: 5 }} className="specify-page-buttons">
                        <Button
                            variant="outlined"
                            color="primary"
                            href="/change-puja-request"
                            startIcon={<Edit />}
                            sx={{ px: 3, py: 1 }}
                        >
                            Change Puja Request
                        </Button>
                        <Button
                            variant="outlined"
                            color="error"
                            href="/cancel-puja-request"
                            startIcon={<Cancel />}
                            sx={{ px: 3, py: 1 }}
                        >
                            Cancel Puja Request
                        </Button>
                    </Box>
                </Container>
            </Box>
        </InnerLayout>
    );
}

export default PujaRequest;
