import React, { useState } from "react";
import { Box, Checkbox, Typography, Modal } from "@mui/material";
import { useAppSettings } from "../../contexts/AppSettingsContext";





function TermsConditions({ type }) {
  const { TempleToursTCTitle, TempleToursTCDescription, SocialHallTCTitle, SocialHallTCDescription, PujaRequestTCTitle, PujaRequestCDescription } = useAppSettings() || {};

  // Define Terms & Conditions for different types
  const termsDataMap = {
    tour: { title: TempleToursTCTitle, description: TempleToursTCDescription },
    puja: { title: PujaRequestTCTitle, description: PujaRequestCDescription },
    socialhall: { title: SocialHallTCTitle, description: SocialHallTCDescription },
  };

  // Select terms based on type
  let termsData = termsDataMap[type] || { title: "", description: "No description available." };

  // Set a default title if it's empty
  const title = termsData.title?.trim() ? termsData.title : "Terms & Conditions";
  const description = termsData.description || "No description available.";

  // State Management
  const [open, setOpen] = useState(false);
  const [agreed, setAgreed] = useState(false);

  // Modal Handlers
  const handleOpen = () => setOpen(true);
  const handleClose = () => agreed && setOpen(false);
  const handleAgree = (e) => {
    setAgreed(e.target.checked);
    if (e.target.checked) {
      setOpen(false);
    }
  };

  return (
    <Box>
      {/* Trigger to Open Popup */}
      <Box
        className="checkbox"
        onClick={handleOpen}
        style={{ cursor: "pointer" }}
      >
        <Checkbox required checked={agreed} /> {title}
      </Box>

      {/* Popup Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box
          className="check-box-popup"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            height: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            overflow: "auto",
          }}
        >
          <Box className="terms-conditions" dangerouslySetInnerHTML={{ __html: description }} />

          {/* Checkbox to Agree and Close Popup */}
          <Box className="check-icon">
            <Checkbox checked={agreed} onChange={handleAgree} /> Agreed
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default TermsConditions;
