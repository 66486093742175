import React from "react";
import PropTypes from "prop-types";
import { Card, CardMedia, CardContent, Typography, Box } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Link } from "react-router-dom";

// Helper function to get the ordinal suffix for the day
const getOrdinal = (day) => {
  const suffixes = ["th", "st", "nd", "rd"];
  const value = day % 100;
  return `${day}${suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0]}`;
};

// Helper function to format the date
const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Extract individual components of the date
  const month = date.toLocaleString('default', { month: 'long' }); // Full month name (e.g., "January")
  const day = date.getDate(); // Day of the month
  const year = date.getFullYear(); // Year
  const hours = date.getHours(); // Hours
  const minutes = date.getMinutes(); // Minutes

  // Convert hours to 12-hour format
  const isAM = hours < 12;
  const hour12 = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
  const formattedTime = `${hour12}:${minutes < 10 ? '0' + minutes : minutes} ${isAM ? 'AM' : 'PM'}`;

  // Return the formatted date with ordinal day suffix
  return `${month} ${getOrdinal(day)}, ${year} - ${formattedTime}`;
};
function EventCard({ event }) {
  return (
    <Box sx={{ px: 1 }} className="event-lists">
      <Link className="link" to={`/events/${event.slug}`} style={{ textDecoration: 'none' }}>
        <Card
          className="theme-radius event-card"
          sx={{ position: "relative", overflow: "hidden" }}
        >
          <CardMedia
            className="theme-radius event-img"
            component="img"
            alt={event.title}
            height="200"
            image={event.image}
          />
          <CardContent sx={{ pb: 4 }}>
            <Typography
              gutterBottom
              variant="h6"
              component="h3"
              dangerouslySetInnerHTML={{ __html: event.title }}
              sx={{ mb: 2 }}
            />

            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                display: "flex",
                alignItems: "center",
                mb: 1,
              }}
            >
              <DateRangeIcon fontSize="small" sx={{ mr: 0.5 }} />
              Start Date: 
              {formatDate(event.start_date_time)}
            </Typography>

            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                display: "flex",
                alignItems: "center",
                mb: 1,
              }}
            >
              <DateRangeIcon fontSize="small" sx={{ mr: 0.5 }} />
              End Date: 
              {formatDate(event.end_date_time)}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ArrowRightAltIcon />
            </Box>
          </CardContent>
        </Card>
      </Link>
    </Box>
  );
}

EventCard.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string.isRequired,
    start_date_time: PropTypes.string.isRequired,
    end_date_time: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    category_name: PropTypes.string,
  }).isRequired,
};

export default EventCard;
