import React, { useState } from "react";
import apiService from "../../services/api";
import InnerLayout from "../../layouts/InnerLayout";
import {
  Box,
  Typography,
  Container,
  TextField,
  Button,
  FormHelperText,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import InnerBanner from "../../images/inner-banner.jpg";
import CallforAction from "../../components/call_for_action/CallForActtion.js";
import TermsConditions from "../puja_request/TermsConditions.js";


const validationSchema = yup.object({
  request_id: yup.string().required("Request ID is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
});

const changeRequestSchema = yup.object({
  name: yup.string().required("Requestor Name is required"),
  comments: yup.string().nullable(),
  terms_accepted: yup.boolean().oneOf([true], "You must accept the terms."),
});

function CancelPujaRequest() {
  const [bookingDetails, setBookingDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  // Form Hooks
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(validationSchema) });

  const {
    register: registerChange,
    handleSubmit: handleSubmitChange,
    formState: { errors: errorsChange },
  } = useForm({ resolver: yupResolver(changeRequestSchema) });

  // API Call to Check Hall Booking
  const handleSearch = async (data) => {
    try {
      const response = await apiService.getPoojaDetails(data);
      if (response.data.success) {
        setBookingDetails(response.data.data);
        setErrorMessage("");
      } else {
        setErrorMessage(
          "No request found with the provided Request ID and Email."
        );
        setBookingDetails(null);
      }
    } catch (error) {
      setErrorMessage("Error fetching booking details. Please try again.");
      setBookingDetails(null);
    }
  };

  console.log("booking details are:",bookingDetails)

  // API Call to Submit Change Request
  const handleChangeRequest = async (data) => {
    try {
      const payload = {
        request_id: bookingDetails.puja_request_id,
        name: data.name,
        email:bookingDetails.email,
        comments: data.comments,
        terms_conditions: data.terms_conditions,
      };
      console.log("form data is :", payload);
      const response = await apiService.cancelPoojaRequest(payload);

      if (response.data.success) {

        sessionStorage.setItem(
          "successPageState",
          JSON.stringify({
            type: "cancel-puja-request",
            requestId: response.data.data.puja_request_id,
          })
        );
        navigate("/success", {
          state: {
            type: "cancel-puja-request",
            requestId: response.data.data.puja_request_id,
          },
        });
      } else {
        setErrorMessage("Error processing request. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting change request:", error);
      setErrorMessage("Error processing request. Please try again.");
    }
  };

  return (
    <InnerLayout>
      <Box component="section" className="inner-banner" sx={{ p: 0 }}>
        <Box component="img" className="banner-pic" src={InnerBanner} />
        <Box className="inner-text">
          <Container maxWidth="xl">
            <Grid
              container
              spacing={2}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item size={{ md: 6 }}>
                <Typography
                  component="h1"
                  variant="h1"
                  className="section-title text-white"
                >
                  Cancel Puja Request
                </Typography>
              </Grid>
              <Grid item size={{ md: 6 }}>
                <CallforAction />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>

      <Box component="section">
        <Container maxWidth="xl">
          <Box className="custom-card" sx={{ mx: "auto" }}>



            {!bookingDetails ? (
              <form onSubmit={handleSubmit(handleSearch)}>
                <Grid container spacing={2}>
                  <Grid item size={{ xs: 12, md: 6 }}>
                    <TextField
                      label="Request ID"
                      variant="outlined"
                      fullWidth
                      {...register("request_id")}
                      error={!!errors.request_id}
                      helperText={errors.request_id?.message}
                      sx={{ mb: 3 }}
                    />
                  </Grid>
                  <Grid item size={{ xs: 12, md: 6 }}>
                    <TextField
                      label="Email"
                      variant="outlined"
                      fullWidth
                      {...register("email")}
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      sx={{ mb: 3 }}
                    />
                  </Grid>
                  <Grid item size={{ xs: 12, md: 12 }}>
                    {errorMessage && (
                      <Typography color="error">{errorMessage}</Typography>
                    )}
                    <Button type="submit" variant="contained" color="primary">
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </form>
            ) : (
              <Box>
                <Grid container spacing={2}>
                  <Grid item size={{ xs: 12, md: 6 }} order={{ xs: 2, md: 1 }}>
                    <Typography
                      variant="h6"
                      className="text-primary font-700 border-title"
                    >
                      Cancel Pooja Request Form
                    </Typography>

                    {/* Change Request Form */}
                    <form onSubmit={handleSubmitChange(handleChangeRequest)}>
                      <Grid container spacing={2}>
                        <Grid item size={{ xs: 12, md: 12 }}>
                          <TextField
                            label="Requestor Name"
                            variant="outlined"
                            fullWidth
                            {...registerChange("name")}
                            error={!!errorsChange.name}
                            helperText={errorsChange.name?.message}
                            sx={{ mt: 3 }}
                          />
                        </Grid>
                        <Grid item size={{ xs: 12, md: 12 }}>
                          <TextField
                            label="Reason for Canccellation"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                            {...registerChange("comments")}
                            sx={{ mt: 3 }}
                          />
                        </Grid>
                        <Grid item size={{ xs: 12, md: 6 }}>


                          <TermsConditions type="socialhall" />




                        </Grid>
                        <Grid item size={{ xs: 12, md: 12 }}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3 }}
                          >
                            Submit Request
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>

                  <Grid item size={{ xs: 12, md: 6 }} order={{ xs: 1, md: 2 }}>
                    <Box className="bg-light p-30">
                      <Typography
                        variant="h6"
                        className="text-primary font-700 border-title"
                      >
                        Booking Details
                      </Typography>

                      <Box className="address-section">
                        <Typography>
                          <Typography component="span">Name:</Typography>{" "}
                          {bookingDetails.name}
                        </Typography>
                        <Typography>
                          <Typography component="span">Request Id:</Typography>{" "}
                          {bookingDetails.puja_request_id}
                        </Typography>
                        <Typography>
                          <Typography component="span">Email:</Typography>{" "}
                          {bookingDetails.email}
                        </Typography>
                        <Typography>
                          <Typography component="span">
                            Date of Event:
                          </Typography>{" "}
                          {bookingDetails.date_of_puja}
                        </Typography>
                      </Box>

                      <Typography
                        variant="p"
                        component="p"
                        className="text-primary font-700 border-title"
                      >
                        Selected Pujas:
                      </Typography>

                      {bookingDetails.selected_pujas.map((hall) => (
                        <Box className="hall-list-item" key={hall.id}>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            {hall.name}
                            <Typography
                              variant="body2"
                              className="font-700 text-primary"
                            >
                              ${hall.puja_cost}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                </Grid>




              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </InnerLayout>
  );
}

export default CancelPujaRequest;
