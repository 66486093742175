import * as React from 'react';
import { useEffect } from 'react';
import { Card, Box, Typography } from "@mui/material";

export default function EventCalender() {
  useEffect(() => {
    // Prevent duplicate script loading
    if (document.querySelector('script[src="//www.trumba.com/scripts/spuds.js"]')) {
      console.log("Trumba script already loaded.");
      return;
    }

    // Load the main Trumba script
    const script = document.createElement('script');
    script.src = '//www.trumba.com/scripts/spuds.js';
    script.async = true;

    script.onload = () => {
      console.log("Trumba script loaded successfully.");
      // Add the spud initialization script
      const spudScript = document.createElement('script');
      spudScript.type = 'text/javascript';
      spudScript.text = `
        $Trumba.addSpud({
          webName: "dailyPujaActivity",
          spudType: "datefinder",
          teaserBase: "https://www.trumba.com/calendars/OmahaHappenings",
          spudId: "trumbaDatefinder" // Links to the div below
        });
      `;
      document.body.appendChild(spudScript);
    };

    script.onerror = () => {
      console.error("Failed to load Trumba script. Check network or Trumba server status.");
    };

    document.body.appendChild(script);

    // Cleanup
    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
      const spudScript = document.querySelector('script[data-trumba-spud]');
      if (spudScript && document.body.contains(spudScript)) {
        document.body.removeChild(spudScript);
      }
    };
  }, []);

  return (
    <Box className="event-calender" sx={{ padding: 2 }}>
      <Card className="event-calender-card" sx={{ padding: 2, maxWidth: 300 }}>
        <Typography component="h2" variant="h6" gutterBottom>
          Daily Activity Calendar
        </Typography>
        <div
          id="trumbaDatefinder" // Matches spudId
          style={{
            height: "200px", // Fixed height for small block
            width: "100%",   // Fits within Card
            overflow: "hidden", // Prevents overflow
            border: "1px solid #ccc", // Optional: visual boundary
            marginTop: '20px'
          }}
        />
      </Card>
    </Box>
  );
}