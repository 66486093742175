import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  Card,
  TextField,
  Button,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import InnerLayout from "../../layouts/InnerLayout";
import apiService from "../../services/api";
import InnerBanner from "../../images/inner-banner.jpg";
import Grid from "@mui/material/Grid2";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TermsConditions from "./TermsConditions";
import CheckAvailability from "../../components/check-availability/CheckAvailability";

const schema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  address: yup.string().nullable(),
  country: yup.string().nullable(),
  state: yup.string().nullable(),
  city: yup.string().nullable(),
  pincode: yup.string().nullable(),
  mobile: yup.string().required("Contact No is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  date_of_puja: yup
    .date()
    .typeError("Invalid date")
    .required("Date of Puja is required"),
  time_of_puja: yup.string().required("Time of Puja is required"),
  alternate_date_of_puja1: yup.date().nullable().typeError("Invalid date"),
  alternate_date_of_puja2: yup.date().nullable().typeError("Invalid date"),
});

const timeSlots = [
  "05:00 - 06:00 AM",
  "06:00 - 07:00 AM",
  "07:00 - 08:00 AM",
  "08:00 - 09:00 AM",
  "09:00 - 10:00 AM",
  "10:00 - 11:00 AM",
  "11:00 - 12:00 PM",
  "12:00 - 01:00 PM",
  "01:00 - 02:00 PM",
  "02:00 - 03:00 PM",
  "03:00 - 04:00 PM",
  "04:00 - 05:00 PM",
  "05:00 - 06:00 PM",
  "06:00 - 07:00 PM",
  "07:00 - 08:00 PM",
];

function PujaRequestForm() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { cart, location } = state || { cart: [], location: "temple" };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (cart.length === 0) {
      // If cart is empty, redirect to the puja-request page
      navigate("/puja-request");
    }
  }, [cart, navigate]);

  const onSubmit = async (data) => {
    try {
      const requestData = { ...data, location, cart };
      console.log("form data is",data)
      const response = await apiService.createPujaRequest(requestData);
      if (response.data.success) {
        localStorage.removeItem("cart");

        sessionStorage.setItem(
          "successPageState",
          JSON.stringify({
            type: "puja-request",
            requestId: response.data.data.puja_request_id,
          })
        );

        navigate("/success", {
          state: {
            type: "puja-request",
            requestId: response.data.data.puja_request_id,
          },
        });
        reset();
      }
    } catch (error) {
      console.error("Error submitting puja request:", error);
    }
  };

  const calculateTotal = () => {
    return cart.reduce(
      (total, item) =>
        total + (location === "temple" ? item.temple_amount : item.home_amount),
      0
    );
  };

  return (
    <InnerLayout>
      <Box component="section" className="inner-banner" sx={{ p: 0 }}>
        <Box component="img" className="banner-pic" src={InnerBanner} />

        <Box className="inner-text">
          <Container maxWidth="xl">
            <Box className="text-center">
              <Typography component="h1" variant="h1" className="text-white">
                Puja Request
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>

      <Box component="section" className="puja-request-form">
        <Container maxWidth="xl">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item size={{ xs: 12, md: 8 }} order={{ xs: 2, md: 1 }}>
                <Box className="custom-card">
                  <Typography
                    variant="h4"
                    className="text-primary font-700"
                    gutterBottom
                  >
                    Puja Request Form 
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item size={{ xs: 12, md: 6 }}>
                      <TextField
                        label="First Name"
                        fullWidth
                        {...register("first_name")}
                        error={!!errors.first_name}
                        helperText={errors.first_name?.message}
                      />
                    </Grid>

                    <Grid item size={{ xs: 12, md: 6 }}>
                      <TextField
                        label="Last Name"
                        fullWidth
                        {...register("last_name")}
                        error={!!errors.last_name}
                        helperText={errors.last_name?.message}
                      />
                    </Grid>

                    <Grid item size={{ xs: 12, md: 6 }}>
                      <TextField
                        label="Contact No"
                        fullWidth
                        {...register("mobile")}
                        error={!!errors.mobile}
                        helperText={errors.mobile?.message}
                      />
                    </Grid>

                    <Grid item size={{ xs: 12, md: 6 }}>
                      <TextField
                        label="Email"
                        fullWidth
                        {...register("email")}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                      />
                    </Grid>

                    <Grid item size={{ xs: 12, md: 12 }}>
                      <TextField
                        label="Address"
                        fullWidth
                        {...register("address")}
                        error={!!errors.address}
                        helperText={errors.address?.message}
                      />
                    </Grid>

                    <Grid item size={{ xs: 12, md: 6 }}>
                      <TextField
                        label="Country"
                        fullWidth
                        {...register("country")}
                        error={!!errors.country}
                        helperText={errors.country?.message}
                      />
                    </Grid>

                    <Grid item size={{ xs: 12, md: 6 }}>
                      <TextField
                        label="State"
                        fullWidth
                        {...register("state")}
                        error={!!errors.state}
                        helperText={errors.state?.message}
                      />
                    </Grid>

                    <Grid item size={{ xs: 12, md: 6 }}>
                      <TextField
                        label="City"
                        fullWidth
                        {...register("city")}
                        error={!!errors.city}
                        helperText={errors.city?.message}
                      />
                    </Grid>

                    <Grid item size={{ xs: 12, md: 6 }}>
                      <TextField
                        label="Pincode"
                        fullWidth
                        {...register("pincode")}
                        error={!!errors.pincode}
                        helperText={errors.pincode?.message}
                      />
                    </Grid>

                    <Grid item size={{ xs: 12, md: 12 }} sx={{ textAlign: "left" }}>
                      <CheckAvailability url="https://www.trumba.com/calendars/private-pujas" type="Puja" />
                    </Grid>

                    <Grid item size={{ xs: 12, md: 6 }}>
                      <TextField
                        label="Date of Puja"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                    
                        onClick={(e) => e.target.showPicker && e.target.showPicker()} 
                        {...register("date_of_puja")}
                        error={!!errors.date_of_puja}
                        helperText={errors.date_of_puja?.message}
                      />
                    </Grid>
                    <Grid item size={{ xs: 12, md: 6 }}>
                      <FormControl fullWidth>
                        <InputLabel>Time of Puja</InputLabel>
                        <Select
                          {...register("time_of_puja")}
                          error={!!errors.time_of_puja}
                          defaultValue=""
                        >
                          {timeSlots.map((slot) => (
                            <MenuItem key={slot} value={slot}>
                              {slot}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item size={{ xs: 12, md: 6 }}>
                      <TextField
                        label="Alternate Date of Puja 1"
                        type="date"
                        fullWidth
                        onClick={(e) => e.target.showPicker && e.target.showPicker()} 
                        InputLabelProps={{ shrink: true }}
                        {...register("alternate_date_of_puja1")}
                        error={!!errors.alternate_date_of_puja1}
                        helperText={errors.alternate_date_of_puja1?.message}
                      />
                    </Grid>

                    <Grid item size={{ xs: 12, md: 6 }}>
                      <TextField
                        label="Alternate Date of Puja 2"
                        type="date"
                        onClick={(e) => e.target.showPicker && e.target.showPicker()} 
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        {...register("alternate_date_of_puja2")}
                        error={!!errors.alternate_date_of_puja2}
                        helperText={errors.alternate_date_of_puja2?.message}
                      />
                    </Grid>

                    <Grid item size={{ xs: 12, md: 6 }}>
                    <TermsConditions type="puja" />
                    </Grid>

                    <Grid item size={{ xs: 12, md: 12 }}>
                      <Button variant="contained" color="primary" type="submit">
                        Submit Request
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid
                className="sticky"
                size={{ xs: 12, md: 4 }}
                order={{ xs: 1, md: 2 }}
                sx={{ position: "sticky", top: "10px", alignSelf: "start" }}
              >
                <Box>
                  <Card className="custom-card no-shadow">
                    <Typography
                      variant="h4"
                      className="text-primary font-700 border-title"
                    >
                      Order Summary
                    </Typography>

                    {cart.map((puja, index) => (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        key={index}
                        className="items-list"
                      >
                        <Typography variant="p" component="span">
                          <CheckCircleIcon /> {puja.name}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="span"
                          className="font-700 text-primary"
                        >
                          $
                          {location === "temple"
                            ? puja.temple_amount
                            : puja.home_amount}
                        </Typography>
                      </Box>
                    ))}

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      className="grand-total"
                    >
                      <Typography
                        variant="h5"
                        component="span"
                        className="font-700"
                      >
                        Total:
                      </Typography>
                      <Typography
                        variant="h5"
                        component="span"
                        className="font-700 text-primary"
                      >
                        ${calculateTotal()}
                      </Typography>
                    </Box>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Box>
    </InnerLayout>
  );
}

export default PujaRequestForm;
