import React, { useEffect, useState } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import Header from "./header/Header.js";
import { Container, Typography, Box, Link } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Footer from "./footer/Footer.js";
import InnerBanner from "../images/inner-banner.jpg";
import Grid from "@mui/material/Grid2";
import CallforAction from "../components/call_for_action/CallForActtion.js";
import htmlConvert from "../themeSettings/htmlConvert.js";
import apiService from "../services/api.js";
import LoadingIcon from "../components/LoadingIcon";
import TempleStaff from "../components/teams/TempleStaff.js";
import Management from "../components/teams/Management.js";
import Deepam from "../components/deepam/Deepam.js";

function DynamicLayout({ theme, toggleTheme }) {
  const { slug } = useParams(); // Get slug from the URL
  const [pageData, setPageData] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const response = await apiService.getCms(); // Fetch the full menu structure
        if (response.data.success) {
          const menu = response.data.data;
          const pageResponse = await apiService.getCmsBySlug(slug); // Fetch current page data
          if (pageResponse.data.success) {
            const data = pageResponse.data.data;
            setPageData(data);

            // Generate breadcrumbs by traversing the menu
            const breadcrumbsArray = buildBreadcrumbs(menu, slug);
            setBreadcrumbs(breadcrumbsArray);
          } else {
            console.error("Page not found");
          }
        } else {
          console.error("Menu not found");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPageData();
  }, [slug]);

  const buildBreadcrumbs = (menu, slug) => {
    const breadcrumbs = [];

    const findPath = (items, path = []) => {
      for (const item of items) {
        const newPath = [...path, { label: item.title, path: `/${item.slug}` }];
        if (item.slug === slug) {
          breadcrumbs.push(...newPath);
          return true;
        }
        if (item.subMenu && findPath(item.subMenu, newPath)) {
          return true;
        }
      }
      return false;
    };

    findPath(menu);
    return breadcrumbs;
  };

  if (loading) {
    return <LoadingIcon />;
  }

  if (!pageData) {
    return <Typography>Page not found</Typography>;
  }

  const { title, banner_image, banner_content, content } = pageData;

  return (
    <>
      <Header theme={theme} toggleTheme={toggleTheme} />
      <Box component="section" className="inner-banner">
        <Box
          component="img"
          className="banner-pic"
          src={banner_image || InnerBanner}
          alt={title}
        />
        <Box className="inner-text">
          <Container maxWidth="xl">
            <Grid
              container
              spacing={2}
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Grid item size={{ xs: 12, md: 6 }} className="page-title-section">
                {/* Dynamic Breadcrumbs */}
                <Breadcrumbs aria-label="breadcrumb" className="text-white breadcrumb">
                  <Link
                    underline="hover"
                    color="inherit"
                    component={RouterLink}
                    to="/"
                  >
                    Home
                  </Link>
                  {breadcrumbs.map((breadcrumb, index) => (
                    <Link
                      key={index}
                      underline="hover"
                      color={
                        index === breadcrumbs.length - 1 ? "textWhite" : "inherit"
                      }
                      component={RouterLink}
                      to={breadcrumb.path}
                      style={index === breadcrumbs.length - 1 ? { color: "white" } : {}}
                    >
                      {breadcrumb.label}
                    </Link>
                  ))}
                </Breadcrumbs>

                <Typography
                  component="h1"
                  variant="h1"
                  className="section-title text-white"
                >
                  {title}
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  className="section-content text-white"
                >
                  {banner_content}
                </Typography>
              </Grid>
              <Grid item size={{ xs: 12, md: 6 }}>
                <CallforAction />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>

      {slug === "temple-staff" ? (
        <Box component="section" className="teams">
          <Container maxWidth="xl">
            <TempleStaff />
          </Container>
        </Box>
      ) : slug === "management" ? (
        <Box component="section" className="teams">
          <Container maxWidth="xl">
          <Management />
          </Container>
        </Box>
      ) : slug === "deepam" ? (
        <Box component="section" className="management">
          <Container maxWidth="xl">
       <Deepam/>
          </Container>
        </Box>
      ) : (
        <Box component="section" className="inner-section">
          <Container maxWidth="xl">
            {content ? (
              htmlConvert({ htmlContent: content })
            ) : (
              <Typography variant="h5" textAlign="center" sx={{ mt: 4 }}>
                Coming Soon
              </Typography>
            )}
          </Container>
        </Box>
      )}
      <Footer />
    </>
  );
}

export default DynamicLayout;
